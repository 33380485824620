
.login_page {
	background-image: url("../../Images/bg-svg-f.svg");
	background-repeat: no-repeat;
	background-size: cover;
	background-color: whitesmoke;
}
.login_container {
	min-height: 65vh;
	align-items: center;
}
.input1 {
	width: 100%;
	height: 50px;
	margin-bottom: 15px;
	border-radius: 5px;
	border: silver 1px solid;
	padding-left: 10px;
	font-size: 17px;
}
form {
	text-align: center;
}

.main {
	min-height: 600px;
	border-radius: 15px;
	background: #fcfbfb;
	box-shadow: 17px 17px 34px #d0d0d0;
	/* margin: auto; */
	border-radius: 8px;
	/* padding-bottom: 120px; */
	/* box-shadow: 0 1px 1px rgb(0 0 0 / 10%); */
	/* width: 400px; */
}
.p1 {
	text-align: center;
	font-size: 20px;
	font-weight: bolder;
	font-family: "Lato", sans-serif;
	color: #2d385e;
	margin-bottom: 20px;
}
.btn1 {
	background-color: #516cf0;
	width: 100%;
	height: 52px;
	color: #ffffff;
	border-radius: 5px;
	border: 1.5px;
	font-size: 20px;
	margin-bottom: 15px;
	font-family: "Lato", sans-serif;
	text-align: center;
	margin-left: 7px;
	margin-top: 30px;
}
.btn1:hover {
	background-color: #ff8500;
}
.btn1:active {
	border: 3px solid #516cf0;
}
.a3 {
	color: #ff8500;
	font-size: 15px;
	text-align: center;
	/* margin: 82px;
	padding-left: 50px; */
}

.sideNote2 {
	width: 600px;
}
.forTitle {
	font-size: 14px;
	font-family: Lato, sans-serif;
	color: #fe8303;
}
h1 {
	font-size: 45px;
	color: #4b456f;
	font-weight: 600;
	line-height: 52px;
}
p {
	font-size: 15px;
	font-family: Lato, sans-serif;
	color: #4b456f;
}

.btn1 {
	width: 200px;
	height: 41px;
	background-color: #fe8303;
	color: white;
	border: none;
	border-radius: 4px;
	font-weight: 400px;
	text-align: center;
	vertical-align: middle;
	border: 1px solid transparent;
	font-size: 1rem;
	margin-left: 10px;
}
.p2 {
	margin-bottom: 20px;
}
h1 {
	margin-bottom: 20px;
}
.showHide2 {
	/* display: flex;
	justify-content: flex-end;
	margin: -52px 25px 0px 360px; */
	opacity: 0.65;
	cursor: pointer;
}
.field-icon {
	float: right;
	margin-top: -55px;
	position: relative;
	z-index: 2;
	margin-right: 20px;
	
  }




